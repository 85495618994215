import React, { useEffect, useRef, useState } from "react"
import queryString from "query-string"
import LoadingBar from "react-top-loading-bar"
import Layout from "../components/layout"
import axios from "axios"
import Select from "react-select"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import * as Yup from "yup"
import { fetch } from "cross-fetch"
import countryJson from "../components/countries-states-cities"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../static/careers.css"
import { Link, navigate } from "gatsby"
// import Loader from "react-loader"
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client"
import ReactHtmlParser from "react-html-parser"
import moment from "moment"

const PositionDetail = (props) => {
  const ref = useRef(null)

  const languages = [
    { code: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
    { code: "aa", name: "Afar", nativeName: "Afaraf" },
    { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
    { code: "ak", name: "Akan", nativeName: "Akan" },
    { code: "sq", name: "Albanian", nativeName: "Shqip" },
    { code: "am", name: "Amharic", nativeName: "አማርኛ" },
    { code: "ar", name: "Arabic", nativeName: "العربية" },
    { code: "an", name: "Aragonese", nativeName: "Aragonés" },
    { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
    { code: "as", name: "Assamese", nativeName: "অসমীয়া" },
    { code: "av", name: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
    { code: "ae", name: "Avestan", nativeName: "avesta" },
    { code: "ay", name: "Aymara", nativeName: "aymar aru" },
    { code: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
    { code: "bm", name: "Bambara", nativeName: "bamanankan" },
    { code: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
    { code: "eu", name: "Basque", nativeName: "euskara, euskera" },
    { code: "be", name: "Belarusian", nativeName: "Беларуская" },
    { code: "bn", name: "Bengali", nativeName: "বাংলা" },
    { code: "bh", name: "Bihari", nativeName: "भोजपुरी" },
    { code: "bi", name: "Bislama", nativeName: "Bislama" },
    { code: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
    { code: "br", name: "Breton", nativeName: "brezhoneg" },
    { code: "bg", name: "Bulgarian", nativeName: "български език" },
    { code: "my", name: "Burmese", nativeName: "ဗမာစာ" },
    { code: "ca", name: "Catalan; Valencian", nativeName: "Català" },
    { code: "ch", name: "Chamorro", nativeName: "Chamoru" },
    { code: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
    {
      code: "ny",
      name: "Chichewa; Chewa; Nyanja",
      nativeName: "chiCheŵa, chinyanja",
    },
    { code: "zh", name: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
    { code: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
    { code: "kw", name: "Cornish", nativeName: "Kernewek" },
    { code: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
    { code: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
    { code: "hr", name: "Croatian", nativeName: "hrvatski" },
    { code: "cs", name: "Czech", nativeName: "česky, čeština" },
    { code: "da", name: "Danish", nativeName: "dansk" },
    { code: "dv", name: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
    { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
    { code: "en", name: "English", nativeName: "English" },
    { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
    { code: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
    { code: "ee", name: "Ewe", nativeName: "Eʋegbe" },
    { code: "fo", name: "Faroese", nativeName: "føroyskt" },
    { code: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
    { code: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
    { code: "fr", name: "French", nativeName: "français, langue française" },
    {
      code: "ff",
      name: "Fula; Fulah; Pulaar; Pular",
      nativeName: "Fulfulde, Pulaar, Pular",
    },
    { code: "gl", name: "Galician", nativeName: "Galego" },
    { code: "ka", name: "Georgian", nativeName: "ქართული" },
    { code: "de", name: "German", nativeName: "Deutsch" },
    { code: "el", name: "Greek, Modern", nativeName: "Ελληνικά" },
    { code: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
    { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
    {
      code: "ht",
      name: "Haitian; Haitian Creole",
      nativeName: "Kreyòl ayisyen",
    },
    { code: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
    { code: "he", name: "Hebrew (modern)", nativeName: "עברית" },
    { code: "hz", name: "Herero", nativeName: "Otjiherero" },
    { code: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
    { code: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
    { code: "hu", name: "Hungarian", nativeName: "Magyar" },
    { code: "ia", name: "Interlingua", nativeName: "Interlingua" },
    { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
    {
      code: "ie",
      name: "Interlingue",
      nativeName: "Originally called Occidental; then Interlingue after WWII",
    },
    { code: "ga", name: "Irish", nativeName: "Gaeilge" },
    { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
    { code: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
    { code: "io", name: "Ido", nativeName: "Ido" },
    { code: "is", name: "Icelandic", nativeName: "Íslenska" },
    { code: "it", name: "Italian", nativeName: "Italiano" },
    { code: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
    {
      code: "ja",
      name: "Japanese",
      nativeName: "日本語 (にほんご／にっぽんご)",
    },
    { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
    {
      code: "kl",
      name: "Kalaallisut, Greenlandic",
      nativeName: "kalaallisut, kalaallit oqaasii",
    },
    { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
    { code: "kr", name: "Kanuri", nativeName: "Kanuri" },
    { code: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
    { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
    { code: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
    { code: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
    { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
    { code: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
    { code: "kv", name: "Komi", nativeName: "коми кыв" },
    { code: "kg", name: "Kongo", nativeName: "KiKongo" },
    {
      code: "ko",
      name: "Korean",
      nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
    },
    { code: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
    { code: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
    { code: "la", name: "Latin", nativeName: "latine, lingua latina" },
    {
      code: "lb",
      name: "Luxembourgish, Letzeburgesch",
      nativeName: "Lëtzebuergesch",
    },
    { code: "lg", name: "Luganda", nativeName: "Luganda" },
    {
      code: "li",
      name: "Limburgish, Limburgan, Limburger",
      nativeName: "Limburgs",
    },
    { code: "ln", name: "Lingala", nativeName: "Lingála" },
    { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
    { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
    { code: "lu", name: "Luba-Katanga", nativeName: "" },
    { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
    { code: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
    { code: "mk", name: "Macedonian", nativeName: "македонски јазик" },
    { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
    { code: "ms", name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
    { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
    { code: "mt", name: "Maltese", nativeName: "Malti" },
    { code: "mi", name: "Māori", nativeName: "te reo Māori" },
    { code: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
    { code: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
    { code: "mn", name: "Mongolian", nativeName: "монгол" },
    { code: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
    {
      code: "nv",
      name: "Navajo, Navaho",
      nativeName: "Diné bizaad, Dinékʼehǰí",
    },
    { code: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
    { code: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
    { code: "ne", name: "Nepali", nativeName: "नेपाली" },
    { code: "ng", name: "Ndonga", nativeName: "Owambo" },
    { code: "nn", name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
    { code: "no", name: "Norwegian", nativeName: "Norsk" },
    { code: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
    { code: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
    { code: "oc", name: "Occitan", nativeName: "Occitan" },
    { code: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
    {
      code: "cu",
      name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
      nativeName: "ѩзыкъ словѣньскъ",
    },
    { code: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
    { code: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
    { code: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
    { code: "pa", name: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
    { code: "pi", name: "Pāli", nativeName: "पाऴि" },
    { code: "fa", name: "Persian", nativeName: "فارسی" },
    { code: "pl", name: "Polish", nativeName: "polski" },
    { code: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
    { code: "pt", name: "Portuguese", nativeName: "Português" },
    { code: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
    { code: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
    { code: "rn", name: "Kirundi", nativeName: "kiRundi" },
    { code: "ro", name: "Romanian, Moldavian, Moldovan", nativeName: "română" },
    { code: "ru", name: "Russian", nativeName: "русский язык" },
    { code: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
    { code: "sc", name: "Sardinian", nativeName: "sardu" },
    { code: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
    { code: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
    { code: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
    { code: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
    { code: "sr", name: "Serbian", nativeName: "српски језик" },
    { code: "gd", name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
    { code: "sn", name: "Shona", nativeName: "chiShona" },
    { code: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
    { code: "sk", name: "Slovak", nativeName: "slovenčina" },
    { code: "sl", name: "Slovene", nativeName: "slovenščina" },
    { code: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
    { code: "st", name: "Southern Sotho", nativeName: "Sesotho" },
    {
      code: "es",
      name: "Spanish; Castilian",
      nativeName: "español, castellano",
    },
    { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
    { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
    { code: "ss", name: "Swati", nativeName: "SiSwati" },
    { code: "sv", name: "Swedish", nativeName: "svenska" },
    { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
    { code: "te", name: "Telugu", nativeName: "తెలుగు" },
    { code: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
    { code: "th", name: "Thai", nativeName: "ไทย" },
    { code: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
    {
      code: "bo",
      name: "Tibetan Standard, Tibetan, Central",
      nativeName: "བོད་ཡིག",
    },
    { code: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
    { code: "tl", name: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
    { code: "tn", name: "Tswana", nativeName: "Setswana" },
    { code: "to", name: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
    { code: "tr", name: "Turkish", nativeName: "Türkçe" },
    { code: "ts", name: "Tsonga", nativeName: "Xitsonga" },
    { code: "tt", name: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
    { code: "tw", name: "Twi", nativeName: "Twi" },
    { code: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
    { code: "ug", name: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
    { code: "uk", name: "Ukrainian", nativeName: "українська" },
    { code: "ur", name: "Urdu", nativeName: "اردو" },
    { code: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
    { code: "ve", name: "Venda", nativeName: "Tshivenḓa" },
    { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
    { code: "vo", name: "Volapük", nativeName: "Volapük" },
    { code: "wa", name: "Walloon", nativeName: "Walon" },
    { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
    { code: "wo", name: "Wolof", nativeName: "Wollof" },
    { code: "fy", name: "Western Frisian", nativeName: "Frysk" },
    { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
    { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
    { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
    { code: "za", name: "Zhuang, Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" },
  ]
  let query = queryString.parse(props.location.search)

  const id = query && query.id ? Number(query.id) : 0
  const [position, setPosition] = useState([])
  const [status, setStatus] = useState([])
  const [source, setSource] = useState([])
  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState(1)
  const notify = () => {
    toast.success("Application Submitted Successfully", {
      position: "bottom-center",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  //const [file, setFile] = useState('');
  const countries = countryJson.getCountries().sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  const getStates = (countryCode) => {
    if (countryCode) {
      return countryJson.getStatesByShort(countryCode)
    }
    return []
  }
  const getCities = (countryCode, state) => {
    if (countryCode) {
      return countryJson.getCities(countryCode, state)
    }
    return []
  }

  const getDateFormate = (date) => {
    var dd = String(date.getDate()).padStart(2, "0")
    var mm = String(date.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = date.getFullYear()
    return { date: yyyy + "-" + mm + "-" + dd }
  }
  const handleSubmit = (e, values, resetForm) => {
    e.preventDefault()
    const formValues = values
    resetForm()
    setLoader(false)
    ref.current.continuousStart()
    const resumeDate = getDateFormate(formValues.resumeDate)
    const itemName = formValues.name
    const columnValues = JSON.stringify({
      name: formValues.name,
      date_cv_resume_submitted35: resumeDate,
      text: formValues.name,
      text27: formValues.country,
      text8: formValues.city,
      text6: formValues.state,
      text3: formValues.skype,
      text69: formValues.linkdin,
      text0: formValues.email,
      dropdown: formValues.source,
      dropdown: formValues.source,
      languages: formValues.languages,
      text65: position.items[0].name,
      text5: formValues.coverLatter,
    })
    client
      .mutate({
        mutation: gql`
          mutation ($itemName: String!, $columnValues: JSON!) {
            create_item(
              board_id: 2042300520
              group_id: "topics"
              item_name: $itemName
              column_values: $columnValues
            ) {
              id
            }
          }
        `,
        variables: { itemName, columnValues },
      })
      .then((result) => {
        const itemId = Number(result.data.create_item.id)
        const formData = new FormData()
        formData.append(
          "query",
          `mutation ($file: File!) {add_file_to_column (item_id:${itemId}, column_id: "files", file:$file) {id}}`
        )
        formData.append("variables[file]", formValues.resume)
        axios
          .post("https://api.monday.com/v2", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization:
                "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEzNzM2ODE4NywidWlkIjoxMzk0NzM2NywiaWFkIjoiMjAyMS0xMi0xNVQxNzowNDo1NC4yNjZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6NjE5MDU1NiwicmduIjoidXNlMSJ9.OYbS9wZZWFZ6GdiimOmVeQOk0MJCDgHQVtlUxajJOYc",
            },
          })
          .then((response) => {
            if (response) {
              setLoader(true)
              ref.current.complete()
              setTab(2)
            }
          })
      })
      .catch((err) => {
        notify()
      })
  }
  const client = new ApolloClient({
    link: new HttpLink({
      uri: "https://api.monday.com/v2",
      fetch,
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEzNzM2ODE4NywidWlkIjoxMzk0NzM2NywiaWFkIjoiMjAyMS0xMi0xNVQxNzowNDo1NC4yNjZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6NjE5MDU1NiwicmduIjoidXNlMSJ9.OYbS9wZZWFZ6GdiimOmVeQOk0MJCDgHQVtlUxajJOYc",
        contentType: "multipart/form-data",
      },
    }),
    cache: new InMemoryCache(),
  })
  useEffect(() => {
    setLoader(false)
    ref.current.continuousStart()
    client
      .query({
        query: gql`
          query {
            items(ids: [ ${Number(query.id)}]) {
              name
              column_values {
                id
                text
                column {
                    title
                    type
                  }
              }
            }
          }
       `,
      })
      .then((result) => {
        setLoader(true)
        ref.current.complete()
        const position = result.data?.items?.length && result.data
        console.log("position", position)
        setPosition(position)
      })
    client
      .query({
        query: gql`
          query {
            boards(ids: 2042300520) {
              columns(ids: ["status", "dropdown"]) {
                settings_str
              }
            }
          }
        `,
      })
      .then((result) => {
        const columns = result.data.boards.length && result.data?.boards[0]
        let labels =
          columns.columns.length && JSON.parse(columns.columns[0].settings_str)
        let labels1 =
          columns.columns.length && JSON.parse(columns.columns[1].settings_str)
        labels = Object.values(labels.labels)
        labels = labels.map((item) => {
          return { label: item, value: item }
        })
        labels1 = labels1.labels.map((item) => {
          return { label: item.name, value: item.name }
        })
        setStatus(labels)
        setSource(labels1)
      })
  }, [])
  const { column_values, name } =
        position.items && position.items[0]
            ? position.items[0]
            : { column_values: {}, name: "" } 
  let positionName,
    industry,
    date,
    location,
    jd,
    details,
    fun,
    recruiter,
    employmentStatus
    if (position && position.items && position.items[0]) {
      positionName = position.items[0].name
       const responseObjectArray = position.items[0].column_values; 
          const extractTextFromColumn = (responseObjectArray, columnTitle) => {
              const columnObj = responseObjectArray.find(obj => obj.column.title === columnTitle);
              return columnObj ? columnObj.text : undefined;
          };
          
          industry = extractTextFromColumn(responseObjectArray, "Industry");
          location = extractTextFromColumn(responseObjectArray, "Location");
          recruiter = extractTextFromColumn(responseObjectArray, "Recruiter");
          employmentStatus = extractTextFromColumn(responseObjectArray, "Employment Status");
          date = extractTextFromColumn(responseObjectArray, "Date Posted");
          jd = extractTextFromColumn(responseObjectArray, "Job Description");
          fun = extractTextFromColumn(responseObjectArray, "Function");
  
    }
  return (
    <>
      <LoadingBar color="#008001" ref={ref} />
      {!loader ? (
        <h1></h1>
      ) : (
        <Layout isOpen={true} positionDetail={true}>
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
          {/* <Loader loaded={loader} options={options} className="spinner" /> */}
          {tab === 1 && position && position.items && position.items[0] && (
            <div class="content-container career-container">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="career-head border-bottom">
                      <h3 class="job-title">{positionName}</h3>
                      <h6>{industry}</h6>
                      <ul class="career-icon-list d-flex flex-wrap">
                        <li>
                          <span class="icon">
                            <img src="calendar-icon.svg" alt="" />
                          </span>
                          {moment(date).format("MMM D")}
                        </li>
                        <li>
                          <span class="icon">
                            <img src="location-icon.svg" alt="" />
                          </span>
                          {location}
                        </li>
                        <li>
                          <span class="icon">
                            <img src="briefcase.svg" alt="" />
                          </span>
                          {employmentStatus}
                        </li>
                        <li />
                      </ul>
                    </div>
                    <div class="career-body">
                      <div class="career-form-block pt-0">
                        <Formik
                          initialValues={initialValues}
                          onSubmit={handleSubmit}
                          validationSchema={validationSchema}
                        >
                          {({
                            resetForm,
                            handleChange,
                            setFieldValue,
                            values,
                            errors,
                            isValid,
                            dirty,
                          }) => (
                            <Form
                              noValidate
                              className="career-form"
                              onSubmit={(e) =>
                                handleSubmit(e, values, resetForm)
                              }
                            >
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="name">Full Name:</label>
                                    <input
                                      name="name"
                                      value={values["name"]}
                                      placeholder="Enter Full Name"
                                      className={
                                        errors.name
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("name", e.target.value)
                                      }
                                    />
                                    {errors.name && errors.name ? (
                                      <div className="invalid-feedback">
                                        {errors.name}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                      name="email"
                                      value={values["email"]}
                                      placeholder="Enter Email"
                                      className={
                                        errors.email
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("email", e.target.value)
                                      }
                                    />
                                    {errors.email && errors.email ? (
                                      <div className="invalid-feedback">
                                        {errors.email}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">Skype:</label>
                                    <input
                                      name="skype"
                                      placeholder="Enter Skype Profile"
                                      className={
                                        errors.skype
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("skype", e.target.value)
                                      }
                                    />
                                    {errors.skype && errors.skype ? (
                                      <div className="invalid-feedback">
                                        {errors.skype}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="linkdin">LinkedIn:</label>
                                    <input
                                      name="linkdin"
                                      placeholder="Enter Linked In Profile"
                                      className={
                                        errors.linkdin
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("linkdin", e.target.value)
                                      }
                                    />
                                    {errors.linkdin && errors.linkdin ? (
                                      <div className="invalid-feedback">
                                        {errors.linkdin}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="phone">Phone:</label>
                                    <input
                                      name="phone"
                                      placeholder="Enter phone"
                                      className={
                                        errors.phone
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("phone", e.target.value)
                                      }
                                    />
                                    {errors.phone && errors.phone ? (
                                      <div className="invalid-feedback">
                                        {errors.phone}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">Country:</label>
                                    <Select
                                      name="country"
                                      options={countries.map((country) => ({
                                        value: country.shortName,
                                        label:
                                          country.emoji + "  " + country.name,
                                      }))}
                                      value={countries
                                        .filter(
                                          (country) =>
                                            country.shortName === values.country
                                        )
                                        .map((country) => ({
                                          value: country.shortName,
                                          label:
                                            country.emoji + "  " + country.name,
                                        }))}
                                      onChange={(selected) =>
                                        setFieldValue("country", selected.value)
                                      }
                                      isSearchable
                                    />
                                    {errors.country && errors.country ? (
                                      <div className="invalid-feedback ">
                                        {errors.country}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">State:</label>
                                    <Select
                                      options={getStates(values.country)?.map(
                                        (state) => ({
                                          value: state,
                                          label: state,
                                        })
                                      )}
                                      onChange={(selected) =>
                                        setFieldValue("state", selected.value)
                                      }
                                      isDisabled={
                                        values.country === "" ? true : false
                                      }
                                      isSearchable
                                    />
                                    {errors.state && errors.state ? (
                                      <div className="invalid-feedback ">
                                        {errors.state}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">City:</label>
                                    <Select
                                      options={getCities(
                                        values.country,
                                        values.state
                                      )?.map((city) => ({
                                        value: city,
                                        label: city,
                                      }))}
                                      onChange={(selected) =>
                                        setFieldValue("city", selected.value)
                                      }
                                      isDisabled={
                                        values.state === "" ? true : false
                                      }
                                      isSearchable
                                    />
                                    {errors.state && errors.state ? (
                                      <div className="invalid-feedback ">
                                        {errors.state}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">Resume Date:</label>
                                    <DatePicker
                                      name={"resumeDate"}
                                      selected={
                                        values["resumeDate"] !== ""
                                          ? new Date(values["resumeDate"])
                                          : new Date()
                                      }
                                      value={
                                        values["resumeDate"] !== ""
                                          ? new Date(values["resumeDate"])
                                          : new Date()
                                      }
                                      onChange={(selected) =>
                                        setFieldValue("resumeDate", selected)
                                      }
                                      dateFormat="dd-MMM-yyyy"
                                      className="form-control"
                                    />
                                    {errors.resumeDate && errors.resumeDate ? (
                                      <div className="invalid-feedback ">
                                        {errors.resumeDate}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <div className="col-lg-6">
                              <div className="form-group">
                                <label htmlFor="email">Status:</label>
                                <Select
                                  name="status"
                                  options={status && status}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(selected) =>
                                    setFieldValue("status", selected.value)
                                  }
                                />
                                {errors.status && errors.status ? (
                                  <div className="invalid-feedback ">
                                    {errors.status}
                                  </div>
                                ) : null}
                              </div>
                            </div> */}
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="email">Resume:</label>
                                    <input
                                      name="resume"
                                      accept="application/pdf"
                                      type="file"
                                      className={
                                        errors.resume
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      placeholder="Enter Resume"
                                      id="resume"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "resume",
                                          event.target.files[0]
                                        )
                                      }}
                                    />
                                    {errors.resume && errors.resume ? (
                                      <div className="invalid-feedback ">
                                        {errors.resume}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="source">Source:</label>
                                    <Select
                                      isMulti
                                      name="status"
                                      options={source && source}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(selected) => {
                                        if (!selected) {
                                          selected = []
                                        }
                                        const sources = selected.map(
                                          (source) => {
                                            return source.value
                                          }
                                        )
                                        setFieldValue(
                                          "source",
                                          sources.toString()
                                        )
                                      }}
                                      isSearchable
                                    />
                                    {errors.source && errors.source ? (
                                      <div className="invalid-feedback">
                                        {errors.source}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="source">Language:</label>
                                    <Select
                                      isMulti
                                      name="status"
                                      options={languages.map((language) => ({
                                        label: language.name,
                                        value: language.name,
                                      }))}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(selected) => {
                                        if (!selected) {
                                          selected = []
                                        }
                                        const language = selected.map(
                                          (languag) => languag.value
                                        )
                                        console.log("language", language)
                                        setFieldValue(
                                          "languages",
                                          language.toString()
                                        )
                                      }}
                                      isSearchable
                                    />
                                    {errors.languages && errors.languages ? (
                                      <div className="invalid-feedback">
                                        {errors.languages}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="linkdin">Apply For:</label>
                                    <input
                                      name="linkdin"
                                      placeholder="Enter Full Name"
                                      className="form-control"
                                      type="text"
                                      value={name}
                                      readOnly
                                    />
                                    {errors.linkdin && errors.linkdin ? (
                                      <div className="invalid-feedback">
                                        {errors.linkdin}
                                      </div>
                                    ) : null}
                                  </div>
                                </div> */}

                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <label htmlFor="linkdin">
                                      Cover Letter:
                                    </label>
                                    <textarea
                                      style={{ height: "100px" }}
                                      name="coverLatter"
                                      className="form-control"
                                      rows="4"
                                      placeholder="Enter Cover Letter"
                                      onChange={(e) =>
                                        setFieldValue(
                                          "coverLatter",
                                          e.target.value
                                        )
                                      }
                                    ></textarea>
                                    {errors.coverLatter &&
                                    errors.coverLatter ? (
                                      <div className="invalid-feedback">
                                        {errors.coverLatter}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="form-group text-right mb-0 d-flex justify-content-end">
                                    <button
                                      type="submit"
                                      className="btn btn-career btn-primary btn-sm 
                                    text-white pr-3 pl-3 nav-link"
                                      disabled={!(isValid && dirty)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {tab === 2 && (
            <div class="content-container thanks-container">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="thanks-box">
                      <div class="thnks-icon">
                        <img src="form-success-icon.svg" alt="" />
                      </div>
                      <h4>
                        We are always interested in meeting great talent! Thank
                        you for sharing your CV/Resume.
                      </h4>
                      <p>
                        You may be contacted for an exploratory conversation to
                        become a Salvex Consultant. Questions? Please contact{" "}
                        <a href="mailto:hr@salvex.com" target={"_blank"}>
                          hr@salvex.com
                        </a>
                      </p>
                      <Link to="/" class="btn btn-career">
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      )}
    </>
  )
}

export default PositionDetail
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object({
  name: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  skype: Yup.string(),
  linkdin: Yup.string(),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  resumeDate: Yup.date().required("Date is required"),
  resume: Yup.mixed().required("A file is required"),
  source: Yup.string().required("source required"),
  languages: Yup.string().required("Languages required"),
  coverLatter: Yup.string().required("cover Latter required"),
})

let initialValues = {
  name: "",
  email: "",
  skype: "",
  linkdin: "",
  phone: "",
  country: "",
  state: "",
  city: "",
  // languges: [],
  // skills: [],
  status: "",
  resumeDate: new Date(),
  resume: "",
  source: "",
}
var options = {
  lines: 13,
  length: 20,
  width: 10,
  radius: 30,
  scale: 1.0,
  corners: 1,
  color: "#07bc0c",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  top: "50%",
  left: "50%",
  shadow: false,
  hwaccel: false,
  position: "absolute",
}
